<template>
    <v-icon
        small
        :color="!value ? 'red' : 'green'"
    >
        {{ value ? 'mdi-check' : 'mdi-close' }}
    </v-icon>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        }
    }
};
</script>
